import { useContext, useEffect } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormContext } from '../contexts/FormContext'
import generateQuote from '../shared/quote'
import Grid from '@material-ui/core/Grid'
import { sanitize, sanitizeEmail } from '../shared/formatting'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { TextField, Checkbox, IconButton } from '@material-ui/core'

const SectionThree = () => {
    // State / Context
    const [formdata, setFormdata] = useContext(FormContext)
    // Handlers
    const addUser = () => {
        let newNumberOfUsers = 1
        if (formdata.basicDetails.numberOfUsers) {
            newNumberOfUsers = formdata.basicDetails.numberOfUsers + 1
        }
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                numberOfUsers: newNumberOfUsers
            }
        })
    }
    const removeUser = (targetID) => {
        // Prevent submissions with 0 users
        if (formdata.basicDetails.numberOfUsers > 1) {
            // Filter out the specific user to delete
            let filteredUsers = formdata.users.filter(
                (user) => user.id !== targetID
            )
            // Update the number of users
            let newNumberOfUsers =
                formdata.basicDetails.numberOfUsers -
                (formdata.users.length - filteredUsers.length)
            setFormdata({
                ...formdata,
                users: filteredUsers,
                basicDetails: {
                    ...formdata.basicDetails,
                    numberOfUsers: newNumberOfUsers
                }
            })
        }
    }
    const updateFirst = (targetID, val) => {
        setFormdata({
            ...formdata,
            users: formdata.users.map((user) => {
                if (user.id === targetID) {
                    user.first = sanitize(val)
                    user.firstError = ''
                }
                return user
            })
        })
    }
    const updateLast = (targetID, val) => {
        setFormdata({
            ...formdata,
            users: formdata.users.map((user) => {
                if (user.id === targetID) {
                    user.last = sanitize(val)
                    user.lastError = ''
                }
                return user
            })
        })
    }
    const updateEmail = (targetID, val) => {
        setFormdata({
            ...formdata,
            users: formdata.users.map((user) => {
                if (user.id === targetID) {
                    user.email = sanitizeEmail(val)
                    user.emailError = ''
                }
                return user
            })
        })
    }
    const updateAccountManager = (targetID, val) => {
        setFormdata({
            ...formdata,
            users: formdata.users.map((user) => {
                if (user.id === targetID) {
                    user.accountManager = val
                }
                return user
            })
        })
    }
    // Recalculate quote and userfields when the number of users change.
    useEffect(() => {
        setFormdata(generateQuote(formdata))
        // I don't want this to fire everytime formdata changes, just formdata.basicDetails.numberOfLocations
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formdata.basicDetails.numberOfUsers])
    return (
        <Grid container item justify="center" spacing={0} alignContent="center">
            <Grid container item xs={12} spacing={3}>
                <Grid item>
                    <Typography>
                        <b>
                            Please completely fill out the below information for
                            your {formdata.basicDetails.numberOfUsers} user
                            {formdata.basicDetails.numberOfUsers > 1 && 's'}.
                        </b>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        This information is used to create logins for the Hosted
                        Server, and each user will have their own username and
                        password sent to the email that you specify.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography>
                        Additionally, you can choose to designate Account
                        Managers, who are individuals who should the ability and
                        permission to add, delete, or modify Hosted Users for
                        your Dealership.
                    </Typography>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>First Name</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Last Name</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Email Address</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Account Manager</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Delete</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formdata.users.map((user, index) => {
                                    return (
                                        <TableRow key={`UserRow${index}`}>
                                            <TableCell>
                                                <TextField
                                                    error={!!user.firstError}
                                                    helperText={user.firstError}
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={user.first}
                                                    onChange={(e) =>
                                                        updateFirst(
                                                            user.id,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    error={!!user.lastError}
                                                    helperText={user.lastError}
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={user.last}
                                                    onChange={(e) =>
                                                        updateLast(
                                                            user.id,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    error={!!user.emailError}
                                                    helperText={user.emailError}
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={user.email}
                                                    onChange={(e) =>
                                                        updateEmail(
                                                            user.id,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        user.accountManager
                                                    }
                                                    onChange={(e) =>
                                                        updateAccountManager(
                                                            user.id,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        removeUser(user.id)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell>
                                        <IconButton onClick={() => addUser()}>
                                            <AddBoxIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SectionThree
