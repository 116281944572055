/**
 * Takes any input and converts/casts it to a string.
 * @param {*} val Input to be converted/casted to a string
 */
const convertToString = (val) => {
    return val + ''
}

/**
 * Scrub a string for invalid characters, and ensure it does not exceed a maximum length.
 * Typical data entry from a user does not require any special characters.
 * @param {string} str The string to sanitize
 * @param {number} [maxlength=75] Specify a max length to the string, will remove off any excess.
 * @param {boolean} [toUpper=false] If true, convers str to uppercase.
 * @returns {string} Sanitized string.
 */
const sanitize = (str, maxlength = 75, toUpper = false) => {
    // Make sure it's a string
    str = convertToString(str)
    // Scrub string of unwanted characters but with support for multiple languages. ('\pL' does not work in Javascript)
    str = str.replace(
        // eslint-disable-next-line no-useless-escape
        /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9 \.,_-]/gim,
        ''
    )
    // Ensure it does not exceed maxlength
    str = str.substring(0, maxlength)
    // Make it uppercase if required
    if (toUpper) {
        str = str.toUpperCase(str)
    }
    // Return the clean string
    return str
}

/**
 * A Specific verison of sanitize for Location Codes
 * @param {string} str The string to sanitize
 * @returns {string} Single character sanitized string
 */
const sanitizeLocationCode = (str) => {
    // Make sure it's a string
    str = convertToString(str)
    // Scrub string of bad characters
    // eslint-disable-next-line no-useless-escape
    str = str.replace(/[^a-zA-Z1-9]/gim, '')
    // Ensure it does not exceed maxlength
    str = str.substring(0, 1)
    // Make it uppercase
    str = str.toUpperCase(str)
    // Return the clean string
    return str
}

/**
 * Scrub a string for invalid characters, and ensures it does not exceed 254 characters.
 * @param {string} str The Email to sanitize
 * @returns {string} The sanitized string
 */
const sanitizeEmail = (str) => {
    // Make sure it's a string
    str = convertToString(str)
    // Scrub string of unwanted characters but with support for multiple languages. ('\pL' does not work in Javascript)
    str = str.replace(
        // eslint-disable-next-line no-useless-escape
        /[^a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9@._-]/gim,
        ''
    )
    // Ensure it does not exceed length of 254 characters, the maximum for RFC 2821
    // RFC 3696 states it can be 320, however RFC 2821 causes those greater than 254 to fail.
    str = str.substring(0, 254)
    // Return clean string.
    return str
}

/**
 * Formats a string to the (111) 222-3333 US phone number format.
 * @param {string} str The phone number to format
 * @returns {string} The altered string, purged of any non-numerical digits, and formatted if 10 digits
 */
const formatPhoneNumber = (str) => {
    // Strip any characters that are not a number
    str = str.replace(/\D/g, '')
    str = str.substring(0, 10)
    // Use Regex to create 3 capturing groups so we can format them.
    let groups = str.match(/^(\d{3})(\d{3})(\d{4})$/)
    // If the match returned the groups, then return with the formatted, or else return nothing.
    if (groups) {
        return '(' + groups[1] + ') ' + groups[2] + '-' + groups[3]
    }
    return str
}

/**
 * Formats a string to only contain numbers. If no number is present in input, 0 is returned.
 * @param {*} val The input to sanitize
 * @param {number} maxVal Maximum value allowed
 * @returns {number} The sanitized number.
 */
const sanitizeNumber = (val, maxVal) => {
    // Make sure it's a string
    val = convertToString(val)
    // Strip any characters that are not a number
    val = val.replace(/\D/g, '')
    val = parseInt(val)
    if (isNaN(val)) {
        return 0
    }
    // If a maximum was provided, ensure it does not exceed that.
    if (maxVal) {
        if (val > maxVal) {
            val = maxVal
        }
    }
    // Convert to number
    return val
}

/**
 * Takes a value and formats it into USD currency format. Note that any decimals are sanitized prior to
 * formatting, so 1234.56 would actually be formatted as "$123,456.00" rather than "$1,234.56" as you may expect.
 * @param {*} val The value to be formatted as currency, either an integer or string of integers is expected,
 * anything not containg numbers will simply be returned as "$0.00"
 * @returns {string} The formatted string
 */
const formatCurrency = (val) => {
    let style = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: 'USD'
    })
    val = sanitizeNumber(val)
    return style.format(val)
}

/**
 * Verifies if a string is in a valid email format
 * @param {string} val The email to validate
 * @returns {boolean} Returns true if valid
 */
const checkEmailFormat = (val) => {
    // Regular expression to validate email.
    // Lifted from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
    // eslint-disable-next-line no-useless-escape
    const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return re.test(val)
}

export {
    sanitize,
    sanitizeLocationCode,
    sanitizeEmail,
    formatPhoneNumber,
    sanitizeNumber,
    formatCurrency,
    checkEmailFormat
}
