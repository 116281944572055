import { useEffect, useContext, useState } from 'react'
import APIModal from './APIModal'
import validateSection from '../shared/validate'
import { FormContext } from '../contexts/FormContext'
import Grid from '@material-ui/core/Grid'
import { StepContext } from '../contexts/StepContext'
import SectionStepper from './SectionStepper'
import SectionOne from '../pages/SectionOne'
import SectionTwo from '../pages/SectionTwo'
import SectionThree from '../pages/SectionThree'
import SectionFour from '../pages/SectionFour'
import SectionFive from '../pages/SectionFive'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import {
    formatCurrency,
    sanitize,
    sanitizeEmail,
    formatPhoneNumber
} from '../shared/formatting'
import { selectPaymentProcessorText } from '../shared/text'

const Sections = () => {
    // State / Context
    const [formdata, setFormdata] = useContext(FormContext)
    const theme = useTheme()
    const [activeStep, setActiveStep] = useContext(StepContext)
    const [showSubmit, setShowSubmit] = useState(false)
    const [callAPI, setCallAPI] = useState(false)
    const [finalData, setFinalData] = useState({})
    let steps = []
    let stepContent = []
    // We don't want to render Section Four (Locations) if the user does not need them.
    if (formdata.basicDetails.numberOfLocations > 0) {
        steps = [
            'Basic Information',
            'Pricing Quote',
            'User Information',
            'Locations',
            'Review & Sign Up'
        ]
        stepContent = [
            <SectionOne key="SectionOne" />,
            <SectionTwo key="SectionTwo" />,
            <SectionThree key="SectionThree" />,
            <SectionFour key="SectionFour" />,
            <SectionFive key="SectionFive" />
        ]
    } else {
        steps = [
            'Basic Information',
            'Pricing Quote',
            'User Information',
            'Review & Sign Up'
        ]
        stepContent = [
            <SectionOne key="SectionOne" />,
            <SectionTwo key="SectionTwo" />,
            <SectionThree key="SectionThree" />,
            <SectionFive key="SectionFive" />
        ]
    }
    // Handlers
    const handleStep = (next) => {
        if (next) {
            if (activeStep < steps.length - 1) {
                let ready = validateSection(
                    activeStep,
                    formdata,
                    setFormdata,
                    steps.length
                )
                if (ready) {
                    setActiveStep(activeStep + 1)
                }
            }
        } else {
            if (activeStep > 0) {
                setActiveStep(activeStep - 1)
            }
        }
    }
    const sendData = () => {
        // Prepare the data, and sanitize it again to be safe
        let submitData = {}
        // Users
        let submitUsers = []
        for (let i = 0; i < formdata.users.length; i++) {
            let accountManagerText = ''
            if (formdata.users[i].accountManager) {
                // In the email we simply was "Yes" if they checked the box, not true/false
                accountManagerText = 'Yes'
            }
            // Append user data to user array
            submitUsers.push({
                first: sanitize(formdata.users[i].first),
                last: sanitize(formdata.users[i].last),
                email: sanitizeEmail(formdata.users[i].email),
                accountManager: accountManagerText
            })
        }
        // Locations
        let locationPlural = ''
        if (formdata.basicDetails.numberOfLocations !== 1) {
            // "1 Location Codes" looks tacky.
            locationPlural = 's'
        }
        let multipleLocations = false
        if (formdata.basicDetails.numberOfLocations > 0) {
            // Sendgrid email templates have limited conditional rendering, we need a bool
            multipleLocations = true
        }
        let submitLocations = []
        for (let i = 0; i < formdata.locations.length; i++) {
            submitLocations.push({
                locationCode: sanitize(formdata.locations[i].locationCode),
                dealershipName: sanitize(formdata.locations[i].dealershipName),
                phoneNumber: formatPhoneNumber(
                    formdata.locations[i].phoneNumber
                ),
                streetAddress: sanitize(formdata.locations[i].streetAddress),
                city: sanitize(formdata.locations[i].city),
                state: sanitize(formdata.locations[i].state),
                zip: sanitize(formdata.locations[i].zip),
                county: sanitize(formdata.locations[i].county),
                dealerLicenseNumber: sanitize(
                    formdata.locations[i].dealerLicenseNumber
                ),
                salesTaxNumber: sanitize(formdata.locations[i].salesTaxNumber),
                lienHolderCode: sanitize(formdata.locations[i].lienHolderCode)
            })
        }
        // Put it all together
        submitData = {
            dealershipName: sanitize(formdata.basicDetails.dealershipName),
            contactName: sanitize(formdata.basicDetails.contactName),
            contactPhoneOne: formatPhoneNumber(
                formdata.basicDetails.contactPhoneOne
            ),
            contactPhoneTwo: formatPhoneNumber(
                formdata.basicDetails.contactPhoneTwo
            ),
            contactEmailOne: sanitizeEmail(
                formdata.basicDetails.contactEmailOne
            ),
            contactEmailTwo: sanitizeEmail(
                formdata.basicDetails.contactEmailTwo
            ),
            paymentProcessing: sanitize(
                selectPaymentProcessorText[
                    formdata.basicDetails.paymentProcessing
                ]
            ),
            serverSize: sanitize(formdata.quoteDetails.serverSize),
            paymentQuote: `${formatCurrency(
                formdata.quoteDetails.priceServer
            )} ${formdata.quoteDetails.paymentFrequency}`,
            locationsQuote: `${formatCurrency(
                formdata.quoteDetails.priceLocations
            )} Yearly (${
                formdata.basicDetails.numberOfLocations
            } Location Code${locationPlural})`,
            multipleLocations: multipleLocations,
            users: submitUsers,
            locations: submitLocations
        }
        setFinalData(submitData)
        setCallAPI(true)
    }
    const closeAPIModal = () => {
        setCallAPI(false)
    }

    useEffect(() => {
        // Ensure activeStep stays within bounds
        if (activeStep > steps.length - 1) {
            setActiveStep(steps.length - 1)
        }
        if (activeStep < 0) {
            setActiveStep(0)
        }
        // Determine if it is the final section to show submit button.
        if (activeStep === steps.length - 1) {
            setShowSubmit(true)
        } else {
            setShowSubmit(false)
        }
        // I don't want this to fire when setActiveStep is fired, that would cause endless loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, steps])
    return (
        <div>
            <Grid
                container
                justify="center"
                style={{ padding: theme.spacing(3) }}
            >
                <Grid item xs={12}>
                    <SectionStepper
                        steps={steps}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{steps[activeStep]}</Typography>
                    <br />
                    {stepContent[activeStep]}
                </Grid>
                <Grid item />
            </Grid>
            <Grid justify="center" container item xs={12} alignItems="center">
                <Grid item xs />
                {activeStep > 0 && (
                    <Grid item xs>
                        <center>
                            <Button
                                onClick={() => handleStep(false)}
                                variant="contained"
                            >
                                <b>Back</b>
                            </Button>
                        </center>
                    </Grid>
                )}
                <Grid item xs>
                    <center>
                        {showSubmit ? (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => sendData()}
                            >
                                <b>Sign Up!</b>
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                onClick={() => handleStep(true)}
                                variant="contained"
                            >
                                <b>Next</b>
                            </Button>
                        )}
                    </center>
                </Grid>
                <Grid item xs />
            </Grid>
            <Grid item>
                <br />
                <br />
                {callAPI && (
                    <APIModal
                        open={callAPI}
                        formdata={finalData}
                        actionClose={closeAPIModal}
                    />
                )}
            </Grid>
        </div>
    )
}

export default Sections
