import { useContext, useEffect } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormContext } from '../contexts/FormContext'
import generateQuote from '../shared/quote'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import {
    sanitize,
    formatPhoneNumber,
    sanitizeNumber,
    sanitizeLocationCode
} from '../shared/formatting'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'

const SectionFour = () => {
    // Styling
    const theme = useTheme()
    // Context
    const [formdata, setFormdata] = useContext(FormContext)
    // const codes = [
    //     '1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I',
    //     'J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
    // ]

    // Handlers
    const updateField = (targetID, field, val) => {
        setFormdata({
            ...formdata,
            locations: formdata.locations.map((location) => {
                if (location.id === targetID) {
                    switch (field) {
                        case 'dealershipName':
                        case 'streetAddress':
                        case 'city':
                        case 'county':
                        case 'salesTaxNumber':
                        case 'lienHolderCode':
                        case 'dealerLicenseNumber':
                            location[field] = sanitize(val)
                            break
                        case 'locationCode':
                            location[field] = sanitizeLocationCode(val)
                            break
                        case 'state':
                            location[field] = sanitize(val, 2, true)
                            break
                        case 'zip':
                            location[field] = sanitize(
                                sanitizeNumber(val) || '',
                                5
                            )
                            break
                        case 'phoneNumber':
                            location[field] = formatPhoneNumber(val)
                            break
                        default:
                    }
                }
                return location
            })
        })
    }
    const addLocation = () => {
        // const addUser = () => {
        //     let newNumberOfUsers = 1
        //     if (formdata.basicDetails.numberOfUsers) {
        //         newNumberOfUsers = formdata.basicDetails.numberOfUsers+1
        //     }
        //     setFormdata({...formdata, basicDetails: {...formdata.basicDetails, numberOfUsers: newNumberOfUsers}})
        // }
        let newNumberOfLocations = 1
        if (formdata.basicDetails.numberOfLocations) {
            newNumberOfLocations = formdata.basicDetails.numberOfLocations + 1
        }
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                numberOfLocations: newNumberOfLocations
            }
        })
        // setFormdata({...formdata, locations: [...formdata.locations,
        //     {
        //         id: shortid.generate(),
        //         locationCode: "",
        //         locationCodeError: "",
        //         dealershipName: "",
        //         dealershipNameError: "",
        //         phoneNumber: "",
        //         phoneNumberError: "",
        //         streetAddress: "",
        //         streetAddressError: "",
        //         city: "",
        //         cityError: "",
        //         state: "",
        //         stateError: "",
        //         zip: "",
        //         zipError: "",
        //         county: "",
        //         countyError: "",
        //         dealerLicenseNumber: "",
        //         dealerLicenseNumberError: "",
        //         salesTaxNumber: "",
        //         salesTaxNumberError: "",
        //         lienHolderCode: "",
        //         lienHolderCodeError: ""
        //     }
        // ]})
    }
    const removeLocation = (targetID) => {
        let filteredLocations = formdata.locations.filter(
            (location) => location.id !== targetID
        )
        let newNumOfLocations =
            formdata.basicDetails.numberOfLocations -
            (formdata.locations.length - filteredLocations.length)
        setFormdata({
            ...formdata,
            locations: filteredLocations,
            basicDetails: {
                ...formdata.basicDetails,
                numberOfLocations: newNumOfLocations
            }
        })
    }
    // Recalculate quote and number of location fields if they change how many locations they need.
    useEffect(() => {
        setFormdata(generateQuote(formdata))
        // I don't want this to fire everytime formdata changes, just formdata.basicDetails.numberOfLocations
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formdata.basicDetails.numberOfLocations])
    return (
        <Grid container item justify="center" spacing={3} alignContent="center">
            <Grid container item xs={12} spacing={3}>
                {formdata.basicDetails.numberOfLocations > 0 && (
                    <>
                        <Grid item>
                            <Typography>
                                <b>
                                    Please fill in the following information
                                    regarding your additional locations that you
                                    need to print on your paperwork.
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" color="secondary">
                                Reminder: A Location Code is only required if
                                you need to print an <i>additional</i>{' '}
                                Dealership Name, Address, Phone Number, or
                                License number on your paperwork.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                If you do not need a Location Code after all,
                                feel free to remove it. To be safe we review and
                                confirm the details with you once we have your
                                server built, just to be sure.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            {formdata.locations.map((location, index) => {
                                return (
                                    <Grid
                                        key={`locationCard${index}`}
                                        container
                                        item
                                        justify="center"
                                        spacing={3}
                                        style={{ padding: theme.spacing(3) }}
                                    >
                                        <Paper elevation={4}>
                                            <Grid
                                                container
                                                item
                                                style={{ padding: '15px' }}
                                                spacing={1}
                                            >
                                                <Grid container item xs={12}>
                                                    <Grid item xs>
                                                        <Typography variant="h6">
                                                            Additional Location
                                                            #{index + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs
                                                        style={{ flexGrow: 1 }}
                                                    />
                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            onClick={() =>
                                                                removeLocation(
                                                                    location.id
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={3}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Location Code:{' '}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            A-Z, 1-9 (Example:
                                                            A)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            error={
                                                                !!location.locationCodeError
                                                            }
                                                            helperText={
                                                                location.locationCodeError
                                                            }
                                                            size="medium"
                                                            value={
                                                                location.locationCode
                                                            }
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'locationCode',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={9}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Dealership Name:{' '}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            Full Business Name
                                                            to print on
                                                            documents for this
                                                            location code.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.dealershipNameError
                                                            }
                                                            helperText={
                                                                location.dealershipNameError
                                                            }
                                                            value={
                                                                location.dealershipName
                                                            }
                                                            placeholder="Frazer Motors"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'dealershipName',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Phone Number:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption">
                                                            Just enter in the
                                                            Numbers, it will
                                                            format
                                                            automatically.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.phoneNumberError
                                                            }
                                                            helperText={
                                                                location.phoneNumberError
                                                            }
                                                            value={
                                                                location.phoneNumber
                                                            }
                                                            placeholder="(888) 963-5369"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'phoneNumber',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Street Address:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.streetAddressError
                                                            }
                                                            helperText={
                                                                location.streetAddressError
                                                            }
                                                            value={
                                                                location.streetAddress
                                                            }
                                                            placeholder="6196 US HWY 11"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'streetAddress',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={7}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            City:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.cityError
                                                            }
                                                            helperText={
                                                                location.cityError
                                                            }
                                                            value={
                                                                location.city
                                                            }
                                                            placeholder="Canton"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'city',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={2}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            State:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.stateError
                                                            }
                                                            helperText={
                                                                location.stateError
                                                            }
                                                            value={
                                                                location.state
                                                            }
                                                            placeholder="NY"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'state',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={3}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            ZIP:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.zipError
                                                            }
                                                            helperText={
                                                                location.zipError
                                                            }
                                                            value={location.zip}
                                                            placeholder="13617"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'zip',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            County:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.countyError
                                                            }
                                                            helperText={
                                                                location.countyError
                                                            }
                                                            value={
                                                                location.county
                                                            }
                                                            placeholder="St. Lawrence"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'county',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Dealer License
                                                            Number:{' '}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.dealerLicenseNumberError
                                                            }
                                                            helperText={
                                                                location.dealerLicenseNumberError
                                                            }
                                                            value={
                                                                location.dealerLicenseNumber
                                                            }
                                                            placeholder="P123456789"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'dealerLicenseNumber',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Sales Tax Number:{' '}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            (If applicable)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.salesTaxNumberError
                                                            }
                                                            helperText={
                                                                location.salesTaxNumberError
                                                            }
                                                            value={
                                                                location.salesTaxNumber
                                                            }
                                                            placeholder="12345678901234567"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'salesTaxNumber',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            Lien Holder Code:{' '}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            (If applicable)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="medium"
                                                            error={
                                                                !!location.lienHolderCodeError
                                                            }
                                                            helperText={
                                                                location.lienHolderCodeError
                                                            }
                                                            value={
                                                                location.lienHolderCode
                                                            }
                                                            placeholder="123456789"
                                                            onChange={(e) =>
                                                                updateField(
                                                                    location.id,
                                                                    'lienHolderCode',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            <Grid item>
                                <IconButton onClick={() => addLocation()}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {/* </Grid> */}
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default SectionFour
