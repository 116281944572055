import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'

const ModalWindow = ({
    open,
    title,
    maxWidth,
    actionClose,
    children,
    noAction
}) => {
    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth ? maxWidth : 'xs'}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                {!noAction && (
                    <Button onClick={() => actionClose()}>Done</Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

ModalWindow.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    actionClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    noAction: PropTypes.bool
}

export default ModalWindow
