import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import { Router } from '@reach/router'
import Sections from './Sections'
import Success from './Success'
import logo from '../images/YellowBlackLogo_transparent_rgb-05.png'

const MainWindow = () => {
    const theme = useTheme()
    return (
        <Grid
            container
            direction="column"
            alignItems="stretch"
            style={{ height: '100%', overflowY: 'none' }}
            wrap="nowrap"
        >
            <Grid
                container
                item
                direction="row"
                style={{ flexWrap: 'nowrap', flexGrow: 1 }}
                justify="center"
                xs
                zeroMinWidth
            >
                <Grid item xs={12} md={10} lg={8} xl={6} zeroMinWidth>
                    <main style={{ flexGrow: 1, padding: theme.spacing(0) }}>
                        <Paper elevation={5}>
                            <div
                                style={{
                                    background: theme.palette.primary.dark
                                }}
                            >
                                <center>
                                    <img src={logo} alt="Hosted Frazer Logo" />
                                </center>
                            </div>
                            <Router>
                                <Sections default path="/" />
                                <Success path="/success" />
                            </Router>
                        </Paper>
                    </main>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MainWindow
