import { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

export const FormContext = createContext()

export const FormProvider = ({ children }) => {
    const [formdata, setFormdata] = useState({
        basicDetails: {
            dealershipName: '',
            dealershipNameError: '',
            contactName: '',
            contactNameError: '',
            contactPhoneOne: '',
            contactPhoneOneError: '',
            contactPhoneTwo: '',
            contactPhoneTwoError: '',
            contactEmailOne: '',
            contactEmailOneError: '',
            contactEmailTwo: '',
            contactEmailTwoError: '',
            numberOfUsers: '',
            numberOfUsersError: '',
            numberOfLocations: 0,
            numberOfLocationsError: '',
            paymentProcessing: '',
            paymentProcessingError: ''
        },
        quoteDetails: {
            paymentFrequency: 'Monthly',
            serverSize: 'Tier 1',
            priceServer: 0,
            priceLocations: 0,
            totalUpFront: 0,
            totalRecurring: 0
        },
        users: [
            {
                id: shortid.generate(),
                first: '',
                firstError: '',
                last: '',
                lastError: '',
                email: '',
                emailError: '',
                accountManager: false
            }
        ],
        locations: [
            {
                id: shortid.generate(),
                locationCode: '',
                locationCodeError: '',
                dealershipName: '',
                dealershipNameError: '',
                phoneNumber: '',
                phoneNumberError: '',
                streetAddress: '',
                streetAddressError: '',
                city: '',
                cityError: '',
                state: '',
                stateError: '',
                zip: '',
                zipError: '',
                county: '',
                countyError: '',
                dealerLicenseNumber: '',
                dealerLicenseNumberError: '',
                salesTaxNumber: '',
                salesTaxNumberError: '',
                lienHolderCode: '',
                lienHolderCodeError: ''
            }
        ],
        section1Complete: false,
        section2Complete: false,
        section3Complete: false,
        section4Complete: false
    })
    return (
        <FormContext.Provider value={[formdata, setFormdata]}>
            {children}
        </FormContext.Provider>
    )
}

FormProvider.propTypes = {
    children: PropTypes.node
}
