// Text for Location Code selection
const selectLocationCodeText = [
    'No, I do not need to print any additional information for other locations on my forms.',
    "Yes, I need to print 1 additional Location's Name, Phone Number, Address, and License Number on my forms.",
    "Yes, I need to print 2 additional Location's Name, Phone Number, Address, and License Number on my forms.",
    "Yes, I need to print 3 additional Location's Name, Phone Number, Address, and License Number on my forms.",
    "Yes, I need to print 4 additional Location's Name, Phone Number, Address, and License Number on my forms."
]

// Text for Payment Processor selection
const selectPaymentProcessorText = [
    'No, I do not use FrazerPay or any payment processor.',
    'Yes, I use FrazerPay.',
    'Yes, I use OpenEdge.',
    'Yes, I use REPAY.',
    'I am not sure.'
]
// Review Text for Payment Processor selection
const reviewPaymentProcessorText = [
    'No',
    'Yes, FrazerPay',
    'Yes, OpenEdge',
    'Yes, REPAY',
    'Unsure'
]

export {
    selectLocationCodeText,
    selectPaymentProcessorText,
    reviewPaymentProcessorText
}
