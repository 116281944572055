import { sanitizeNumber } from '../formatting'
import shortid from 'shortid'

/**
 * When the user changes the number of users they need, this adds or removes user fields and returns the updated user list.
 * @param {int} nUsers Target number of users we need to have
 * @param {[]} userList Current list of users that we need to add/remove fields from
 */
const autoUsers = (nUsers, userList) => {
    // let nUsers = formdata.basicDetails.numberOfUsers;
    // let userList = formdata.users;
    if (nUsers !== userList.length) {
        // Add more user fields, or remove fields if we now have too many.
        if (nUsers > userList.length) {
            // Adding additional fields
            let diff = nUsers - userList.length
            for (let i = 0; i < diff; i++) {
                userList = [
                    ...userList,
                    {
                        id: shortid.generate(),
                        first: '',
                        firstError: '',
                        last: '',
                        lastError: '',
                        email: '',
                        emailError: '',
                        accountManager: false
                    }
                ]
            }
        } else if (nUsers < userList.length) {
            // Removing excess fields
            let diff = userList.length - nUsers
            for (let i = 0; i < diff; i++) {
                // Remove last field
                userList.pop()
            }
        }
    }
    return userList
}

/**
 * When the user changes the number of locations they need, this auto adds/removes fields as needed.
 * @param {int} nLocations Target number of locations required
 * @param {[]} locationList Current list of locations that we need to add/remove fields from
 */
const autoLocations = (nLocations, locationList) => {
    if (nLocations !== locationList.length) {
        // Add or remove location fields as needed
        if (nLocations > locationList.length) {
            // Adding additional fields
            let diff = nLocations - locationList.length
            for (let i = 0; i < diff; i++) {
                locationList = [
                    ...locationList,
                    {
                        id: shortid.generate(),
                        locationCode: '',
                        locationCodeError: '',
                        dealershipName: '',
                        dealershipNameError: '',
                        phoneNumber: '',
                        phoneNumberError: '',
                        streetAddress: '',
                        streetAddressError: '',
                        city: '',
                        cityError: '',
                        state: '',
                        stateError: '',
                        zip: '',
                        zipError: '',
                        county: '',
                        countyError: '',
                        dealerLicenseNumber: '',
                        dealerLicenseNumberError: '',
                        salesTaxNumber: '',
                        salesTaxNumberError: '',
                        lienHolderCode: '',
                        lienHolderCodeError: ''
                    }
                ]
            }
        } else if (nLocations < locationList.length) {
            // Removing excess fields
            let diff = locationList.length - nLocations
            for (let i = 0; i < diff; i++) {
                // Remove last field
                locationList.pop()
            }
        }
    }
    return locationList
}

/**
 * Used to recaculate the pricing quote, and return an updated state object. Typically wrapped by a setter
 * Example: setFormdata(generatequote(formdata))
 * @param {Object} formdata Pass in the context, returns the modified object for the Setter
 */
const generateQuote = (formdata) => {
    // Calculate server size and price:
    // Tier 1: $199 a month (1-6 users)
    // Tier 2: $299 a month (7-15 users)
    // Tier 3: $399 a month (16-25 users)
    // Tier 4: $499 a month (26-35 users)
    // Tier 5: $599 a month (35+ users)
    let tServer = ''
    let pServer = 0
    let nUsers = formdata.basicDetails.numberOfUsers
    let nLocations = formdata.basicDetails.numberOfLocations
    if (nUsers <= 6) {
        tServer = 'Tier 1 Hosted Server for 1-6 Users'
        pServer = 199
    } else if (nUsers <= 15) {
        tServer = 'Tier 2 Hosted Server for 7-15 Users'
        pServer = 299
    } else if (nUsers <= 25) {
        tServer = 'Tier 3 Hosted Server for 16-25 Users'
        pServer = 399
    } else if (nUsers <= 35) {
        tServer = 'Tier 4 Hosted Server for 26-35 Users'
        pServer = 499
    } else {
        tServer = 'Tier 5 Hosted Server for over 35 Users'
        pServer = 599
    }
    // Location codes ($300 per year, per code)
    let pLocations =
        sanitizeNumber(formdata.basicDetails.numberOfLocations) * 300
    // Determine Up Front and Recurring costs for quote
    let pUpFront = pServer + pLocations
    let pRecurring = pServer + pLocations

    // Auto Generate user fields in our state (For section 3)
    let updatedUsers = autoUsers(nUsers, formdata.users)

    // Auto Generate location fields in our state (For section 4, if locations > 0)
    let updatedLocations = autoLocations(nLocations, formdata.locations)

    // Save calculations and return updated object.
    return {
        ...formdata,
        basicDetails: {
            ...formdata.basicDetails,
            numberOfUsers: updatedUsers.length,
            numberOfLocations: updatedLocations.length
        },
        quoteDetails: {
            ...formdata.quoteDetails,
            paymentFrequency: 'Monthly',
            serverSize: tServer,
            priceServer: pServer,
            priceLocations: pLocations,
            totalUpFront: pUpFront,
            totalRecurring: pRecurring
        },
        users: updatedUsers,
        locations: updatedLocations
    }
}

export default generateQuote
