import { useState, useEffect } from 'react'
import ModalWindow from './ModalWindow'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'
import CompareArrowsIcon from '@material-ui/icons/CompareArrows'
import ErrorIcon from '@material-ui/icons/Error'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { navigate } from '@reach/router'

const APIModal = ({ open, formdata, actionClose }) => {
    // State / Context
    const [displayData, setDisplayData] = useState(<Sending />)

    // API Interaction
    const api = useAPIClient()
    // Mutation
    const { mutate, isLoading } = useMutation(api.backend.submitInfo)
    // Mutation handler
    const handleMutate = () => {
        // Fire request
        mutate(
            { submitData: formdata },
            {
                onSuccess: () => {
                    actionClose()
                    navigate('/success')
                },
                onError: () => {
                    setDisplayData(
                        <Problem message="Failed to submit your information, please try again." />
                    )
                }
            }
        )
    }
    useEffect(() => {
        // Fire the API call on render
        handleMutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ModalWindow
            open={open}
            maxWidth="xs"
            actionClose={actionClose}
            noAction={isLoading}
        >
            {displayData}
        </ModalWindow>
    )
}

APIModal.propTypes = {
    open: PropTypes.bool.isRequired,
    formdata: PropTypes.object.isRequired,
    actionClose: PropTypes.func.isRequired
}

export default APIModal

const Sending = () => {
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography variant="h6">Sending Data</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <CompareArrowsIcon style={{ fontSize: 64 }} />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography>
                        Sending data to the Hosted Team, please do not close the
                        browser.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
        </Grid>
    )
}

const Problem = ({ message }) => {
    // It's possible to have multiple problems. Separated with "\n"
    const [messages, setMessages] = useState([])
    useEffect(() => {
        setMessages(message.split('\n'))
    }, [message])
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography variant="h6">Failed</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <ErrorIcon style={{ fontSize: 64 }} />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography>The following problems occurred:</Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    {messages &&
                        messages.length > 0 &&
                        messages.map((issue, index) => (
                            <Typography key={`${index}issues`}>
                                {index + 1} - {issue}
                            </Typography>
                        ))}
                    <br />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography>
                        You can close this window and try again. If problem
                        persists you may need to try a different web browser, or
                        call 888-963-5369 for help.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
        </Grid>
    )
}
Problem.propTypes = {
    message: PropTypes.string.isRequired
}
