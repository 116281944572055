import { useContext, useEffect } from 'react'
import { FormContext } from '../contexts/FormContext'
import generateQuote from '../shared/quote'
import Grid from '@material-ui/core/Grid'
import { formatCurrency } from '../shared/formatting'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

const SectionTwo = () => {
    // Context
    const [formdata, setFormdata] = useContext(FormContext)

    // Recalculate quote when the payment frequency is changed.
    useEffect(() => {
        setFormdata(generateQuote(formdata))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formdata.quoteDetails.paymentFrequency])
    return (
        <Grid container item justify="center" spacing={0} alignContent="center">
            <Grid container item xs={12} spacing={3}>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Based on the information you have provided, here are
                            the costs for Hosted Frazer:
                        </Typography>
                        <Typography variant="body2">
                            *Taxes may apply depending on your locale (Not
                            calculated below).
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <b>
                                            {formatCurrency(
                                                formdata.quoteDetails
                                                    .priceServer
                                            )}{' '}
                                            {
                                                formdata.quoteDetails
                                                    .paymentFrequency
                                            }
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        {`${formdata.quoteDetails.serverSize} (Frazer Software included)`}
                                    </TableCell>
                                </TableRow>
                                {formdata.basicDetails.numberOfLocations >
                                    0 && (
                                    <TableRow>
                                        <TableCell>
                                            <b>
                                                {formatCurrency(
                                                    formdata.quoteDetails
                                                        .priceLocations
                                                )}{' '}
                                                Yearly
                                            </b>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                formdata.basicDetails
                                                    .numberOfLocations
                                            }{' '}
                                            Location Code
                                            {formdata.basicDetails
                                                .numberOfLocations > 1 && 's'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SectionTwo
