import { useContext } from 'react'
import { FormContext } from '../contexts/FormContext'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'

const Success = () => {
    // State / Context
    const [formdata] = useContext(FormContext)
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <br />
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <Typography variant="h6">Success!</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item>
                    <CheckIcon style={{ fontSize: 128 }} />
                </Grid>
            </Grid>
            <Grid item xs={12} container justify="center">
                <Grid item xs={8}>
                    <Typography>
                        The Hosted Team has received your information and will
                        begin to build a server for you. They will contact you
                        and schedule a time to migrate your data once the server
                        is prepared. (This process typically takes 1-2 business
                        days). A copy of your submission has been emailed to{' '}
                        {formdata.basicDetails.contactEmailOne}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
        </Grid>
    )
}

export default Success
