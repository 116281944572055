import { useContext } from 'react'
import { FormContext } from '../contexts/FormContext'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

const SectionStepper = ({ steps, activeStep, setActiveStep }) => {
    // State / Context
    const [formdata] = useContext(FormContext)
    // Handler
    const handleClick = (index) => {
        if (index > activeStep) {
            // If the user is skipping ahead, ensure that at least the section prior to target section has been completed.
            if (formdata[`section${activeStep + 1}Complete`]) {
                setActiveStep(index)
            }
        } else {
            // If they are using stepper to go back, assume they've completed and allow it
            setActiveStep(index)
        }
    }
    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
                <Step key={label} onClick={() => handleClick(index)}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}

SectionStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    activeStep: PropTypes.number.isRequired,
    setActiveStep: PropTypes.func.isRequired
}

export default SectionStepper
