import { useContext } from 'react'
import {
    EditDealershipName,
    EditPrimaryContact,
    EditPrimaryPhone,
    EditSecondaryPhone,
    EditPrimaryEmail,
    EditSecondaryEmail,
    EditNumberOfUsers,
    EditLocationCodes,
    EditPaymentProcessing
} from '../shared/fields'
import { FormContext } from '../contexts/FormContext'
import Grid from '@material-ui/core/Grid'

const SectionOne = () => {
    // Context
    const [formdata, setFormdata] = useContext(FormContext)
    return (
        <Grid container justify="center" spacing={0} alignContent="center">
            <Grid container item spacing={3}>
                <EditDealershipName
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditPrimaryContact
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditPrimaryPhone
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditSecondaryPhone
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditPrimaryEmail
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditSecondaryEmail
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditNumberOfUsers
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditLocationCodes
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
                <EditPaymentProcessing
                    formdata={formdata}
                    setFormdata={setFormdata}
                />
            </Grid>
        </Grid>
    )
}

export default SectionOne
