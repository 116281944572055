import { APIClientProvider } from 'react-toolbox/APIClient'
import { endpoints } from './api'
import { FormProvider } from './contexts/FormContext'
import HostedTheme from './contexts/HostedTheme'
import MainWindow from './components/MainWindow'
import { StepProvider } from './contexts/StepContext'
import { QueryClient, QueryClientProvider } from 'react-query'

function App() {
    //Set up provider for react query with default config
    const queryClient = new QueryClient()
    // Wrap all of our providers around our app
    return (
        <div className="App">
            <HostedTheme>
                <StepProvider>
                    <FormProvider>
                        <QueryClientProvider client={queryClient}>
                            <APIClientProvider
                                baseURL={process.env.REACT_APP_BASE_URL}
                                endpoints={endpoints}
                            >
                                <MainWindow />
                            </APIClientProvider>
                        </QueryClientProvider>
                    </FormProvider>
                </StepProvider>
            </HostedTheme>
        </div>
    )
}

export default App
