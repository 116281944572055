import { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const StepContext = createContext()

export const StepProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(0)
    return (
        <StepContext.Provider value={[activeStep, setActiveStep]}>
            {children}
        </StepContext.Provider>
    )
}

StepProvider.propTypes = {
    children: PropTypes.node
}
