import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {
    sanitize,
    sanitizeEmail,
    formatPhoneNumber,
    sanitizeNumber
} from '../formatting'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { selectLocationCodeText, selectPaymentProcessorText } from '../text'
import generateQuote from '../quote'

const EditDealershipName = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                dealershipName: sanitize(val),
                dealershipNameError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    What is the name of your Dealership?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.dealershipNameError}
                    helperText={formdata.basicDetails.dealershipNameError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="ABC Motors of XYZ, LLC"
                    value={formdata.basicDetails.dealershipName}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditDealershipName.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditPrimaryContact = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                contactName: sanitize(val),
                contactNameError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Who should we contact regarding Hosted Frazer?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.contactNameError}
                    helperText={formdata.basicDetails.contactNameError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="John Doe"
                    value={formdata.basicDetails.contactName}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditPrimaryContact.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditPrimaryPhone = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                contactPhoneOne: formatPhoneNumber(val),
                contactPhoneOneError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    What's the best Phone Number to contact that person?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.contactPhoneOneError}
                    helperText={formdata.basicDetails.contactPhoneOneError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="888 963 5369"
                    value={formdata.basicDetails.contactPhoneOne}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditPrimaryPhone.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditSecondaryPhone = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                contactPhoneTwo: formatPhoneNumber(val),
                contactPhoneTwoError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    (Optional) Secondary Phone Number in case we are unable to
                    reach anyone?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.contactPhoneTwoError}
                    helperText={formdata.basicDetails.contactPhoneTwoError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="888 963 5369"
                    value={formdata.basicDetails.contactPhoneTwo}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditSecondaryPhone.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditPrimaryEmail = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                contactEmailOne: sanitizeEmail(val),
                contactEmailOneError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    What is your Email Address?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.contactEmailOneError}
                    helperText={formdata.basicDetails.contactEmailOneError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="myemail@example.com"
                    value={formdata.basicDetails.contactEmailOne}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditPrimaryEmail.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditSecondaryEmail = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                contactEmailTwo: sanitizeEmail(val),
                contactEmailTwoError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    (Optional) Secondary Email Address?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.contactEmailTwoError}
                    helperText={formdata.basicDetails.contactEmailTwoError}
                    size="medium"
                    variant="standard"
                    fullWidth
                    placeholder="myotheremail@example2.com"
                    value={formdata.basicDetails.contactEmailTwo}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditSecondaryEmail.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditNumberOfUsers = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                numberOfUsers: sanitizeNumber(val, 50),
                numberOfUsersError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    How many users need access to Frazer in total?
                </Typography>
                <Typography variant="caption">
                    Note: This should include <i>every</i> person that needs
                    access to Frazer, not just outside your office.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.numberOfUsersError}
                    helperText={formdata.basicDetails.numberOfUsersError}
                    size="medium"
                    variant="standard"
                    placeholder="Example: 5"
                    value={formdata.basicDetails.numberOfUsers}
                    onBlur={() => generateQuote(formdata)}
                    onChange={(e) => updateField(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

EditNumberOfUsers.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditLocationCodes = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                numberOfLocations: sanitizeNumber(val, 4),
                numberOfLocationsError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Do you require any Location Codes?
                </Typography>
                <Typography variant="caption">
                    Only required if your paperwork needs to print{' '}
                    <i>separate</i> business information. (Main lot is ABC
                    Autos, but second lot needs to print XYZ Motors)
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.numberOfLocationsError}
                    helperText={formdata.basicDetails.numberOfLocationsError}
                    select
                    size="small"
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                        native: true
                    }}
                    value={formdata.basicDetails.numberOfLocations}
                    onBlur={() => generateQuote(formdata)}
                    onChange={(e) => updateField(e.target.value)}
                >
                    <option key="locations">(Select Option)</option>
                    {selectLocationCodeText.map((text, index) => (
                        <option key={`locations${index}`} value={index}>
                            {text}
                        </option>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}

EditLocationCodes.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

const EditPaymentProcessing = ({ formdata, setFormdata }) => {
    // Handler
    const updateField = (val) => {
        setFormdata({
            ...formdata,
            basicDetails: {
                ...formdata.basicDetails,
                paymentProcessing: sanitizeNumber(val, 4),
                paymentProcessingError: ''
            }
        })
    }
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="body1">
                    Do you use FrazerPay or any payment processor in the Frazer
                    Software?
                </Typography>
                <Typography variant="caption">
                    This helps ensure we install any required drivers when we
                    build your Hosted Server.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    error={!!formdata.basicDetails.paymentProcessingError}
                    helperText={formdata.basicDetails.paymentProcessingError}
                    select
                    size="small"
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                        native: true
                    }}
                    value={formdata.basicDetails.paymentProcessing}
                    onChange={(e) => updateField(e.target.value)}
                >
                    <option key="processors">(Select an Option)</option>
                    {selectPaymentProcessorText.map((text, index) => (
                        <option key={`processors${index}`} value={index}>
                            {text}
                        </option>
                    ))}
                </TextField>
            </Grid>
        </Grid>
    )
}

EditPaymentProcessing.propTypes = {
    formdata: PropTypes.object.isRequired,
    setFormdata: PropTypes.func.isRequired
}

export {
    EditDealershipName,
    EditPrimaryContact,
    EditPrimaryPhone,
    EditSecondaryPhone,
    EditPrimaryEmail,
    EditSecondaryEmail,
    EditNumberOfUsers,
    EditLocationCodes,
    EditPaymentProcessing
}
