import {
    sanitize,
    sanitizeEmail,
    formatPhoneNumber,
    sanitizeNumber,
    checkEmailFormat
} from '../formatting'
//tempFormdata.basicDetails = {...tempFormdata.basicDetails, }

/**
 * Validates the specified section and returns whether or not it is ready
 * @param {number} step The current section to validate
 * @param {Object} formdata The data we need to validate
 * @param {Function} setFormdata Setter for the data
 * @param {number} steps How many steps there are, used to track Section 4 whether or not to validate Section Four specifically
 */
const validateSection = (step, formdata, setFormdata, steps) => {
    // If anything fails, set ready to false
    let ready = true
    // Make a copy of formdata so we can modify it on the fly, then call the setter at the end.
    let tempFormdata = formdata

    // Section One (Basic Details), if there are problems, set errors, otherwise ensure they are cleared.
    if (step === 0) {
        // dealershipName
        if (sanitize(tempFormdata.basicDetails.dealershipName) === '') {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                dealershipNameError: 'This is a required field'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                dealershipNameError: ''
            }
        }
        // contactName
        if (sanitize(tempFormdata.basicDetails.contactName) === '') {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactNameError: 'This is a required field'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactNameError: ''
            }
        }
        // contactPhoneOne
        let contactPhoneOne = formatPhoneNumber(
            tempFormdata.basicDetails.contactPhoneOne
        )
        if (contactPhoneOne === '' || contactPhoneOne.length !== 14) {
            // With formatting a 10 digit phone number should be 14 characters
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactPhoneOneError:
                    'This is a required field: Please enter a valid 10-digit phone number'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactPhoneOneError: ''
            }
        }
        // contactPhoneTwo (Optional, but if they do enter something we need to validate it.)
        let contactPhoneTwo = formatPhoneNumber(
            tempFormdata.basicDetails.contactPhoneTwo
        )
        if (contactPhoneTwo !== '' && contactPhoneTwo.length !== 14) {
            // With formatting a 10 digit phone number should be 14 characters
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactPhoneTwoError:
                    'Please enter a valid 10-digit phone number, or leave the field blank'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactPhoneTwoError: ''
            }
        }
        // contactEmailOne
        let contactEmailOne = sanitizeEmail(
            tempFormdata.basicDetails.contactEmailOne
        )
        if (contactEmailOne === '' || !checkEmailFormat(contactEmailOne)) {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactEmailOneError:
                    'This is a required field: Please enter a valid email address'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactEmailOneError: ''
            }
        }
        // contactEmailTwo (Optional, but if they do enter something we need to validate it.)
        let contactEmailTwo = sanitizeEmail(
            tempFormdata.basicDetails.contactEmailTwo
        )
        if (contactEmailTwo !== '' && !checkEmailFormat(contactEmailTwo)) {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactEmailTwoError:
                    'Please enter a valid email address, or leave the field blank'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                contactEmailTwoError: ''
            }
        }
        // numberOfUsers
        let numberOfUsers = sanitizeNumber(
            tempFormdata.basicDetails.numberOfUsers,
            50
        )
        if (numberOfUsers === '' || numberOfUsers === 0) {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                numberOfUsersError:
                    'Please enter how many users need access to Frazer in total'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                numberOfUsersError: ''
            }
        }
        // numberOfLocations
        if (
            sanitizeNumber(tempFormdata.basicDetails.numberOfLocations, 4) ===
            ''
        ) {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                numberOfLocationsError: 'Please make a selection'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                numberOfLocationsError: ''
            }
        }
        // paymentProcessing
        if (
            sanitizeNumber(tempFormdata.basicDetails.paymentProcessing, 4) ===
            ''
        ) {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                paymentProcessingError: 'Please make a selection'
            }
            ready = false
        } else {
            tempFormdata.basicDetails = {
                ...tempFormdata.basicDetails,
                paymentProcessingError: ''
            }
        }
        // Mark as complete if ready
        if (ready) {
            tempFormdata.section1Complete = true
        } else {
            tempFormdata.section1Complete = false
        }
    }
    // Section Two (Quote, no validation needed)
    if (step === 1) {
        tempFormdata.section2Complete = true
    }

    // Section Three (Users), if there are problems, set errors, otherwise ensure they are cleared.
    if (step === 2) {
        const users = tempFormdata.users
        for (let i = 0; i < users.length; i++) {
            // First name
            if (sanitize(users[i].first) === '') {
                users[i].firstError = 'Required'
                ready = false
            } else {
                users[i].firstError = ''
            }
            // Last name
            if (sanitize(users[i].last) === '') {
                users[i].lastError = 'Required'
                ready = false
            } else {
                users[i].lastError = ''
            }
            // Email
            let email = sanitizeEmail(users[i].email)
            if (email === '' || !checkEmailFormat(email)) {
                users[i].emailError = 'Valid Email Required'
                ready = false
            } else {
                users[i].emailError = ''
            }
        }
        tempFormdata.users = users
        // tempFormdata = {...tempFormdata, users: users}
        // Mark as complete if ready
        if (ready) {
            tempFormdata.section3Complete = true
        } else {
            tempFormdata.section3Complete = false
        }
    }

    // Section Four (If no location codes this will not even be rendered, Section 5 would actually take this place)
    // If there are problems, set errors, otherwise ensure they are cleared.
    if (step === 3 && steps === 5) {
        const locations = tempFormdata.locations
        for (let i = 0; i < locations.length; i++) {
            // Location Code
            if (sanitize(locations[i].locationCode) === '') {
                locations[i].locationCodeError = 'Required'
                ready = false
            } else {
                locations[i].locationCodeError = ''
            }
            // Dealership Name
            if (sanitize(locations[i].dealershipName) === '') {
                locations[i].dealershipNameError = 'Required'
                ready = false
            } else {
                locations[i].dealershipNameError = ''
            }
            // Phone Number
            if (formatPhoneNumber(locations[i].phoneNumber) === '') {
                locations[i].phoneNumberError = 'Required'
                ready = false
            } else {
                locations[i].phoneNumberError = ''
            }
            // Street Address
            if (sanitize(locations[i].streetAddress) === '') {
                locations[i].streetAddressError = 'Required'
                ready = false
            } else {
                locations[i].streetAddressError = ''
            }
            // City
            if (sanitize(locations[i].city) === '') {
                locations[i].cityError = 'Required'
                ready = false
            } else {
                locations[i].cityError = ''
            }
            // State
            let state = sanitize(locations[i].state, 2, true)
            if (state === '') {
                locations[i].stateError = 'Required'
                ready = false
            } else {
                locations[i].stateError = ''
            }
            // ZIP
            let zip = sanitize(sanitizeNumber(locations[i].zip), 5)
            if (zip === '' || zip.length !== 5) {
                locations[i].zipError = 'Required'
                ready = false
            } else {
                locations[i].zipError = ''
            }
            // County
            if (sanitize(locations[i].county) === '') {
                locations[i].countyError = 'Required'
                ready = false
            } else {
                locations[i].countyError = ''
            }
            // Dealer License Number
            if (sanitize(locations[i].dealerLicenseNumber) === '') {
                locations[i].dealerLicenseNumberError = 'Required'
                ready = false
            } else {
                locations[i].dealerLicenseNumberError = ''
            }
            // Sales Tax Number (Optional, no real requirements, no validation needed)
            // Lien Holder Code (Optional, no real requirements, no validation needed)
            // Mark as complete if ready
            if (ready) {
                tempFormdata.section4Complete = true
            } else {
                tempFormdata.section4Complete = false
            }
        }
    }

    // Now we can use the callback function to update the context from the calling component.
    // We create a new object and inject tempFormdata here to force a re-render
    setFormdata({ ...tempFormdata })
    return ready
}

export default validateSection
