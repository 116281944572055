import React, { useState, useContext, useEffect } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import {
    EditDealershipName,
    EditPrimaryContact,
    EditPrimaryPhone,
    EditSecondaryPhone,
    EditPrimaryEmail,
    EditSecondaryEmail,
    EditPaymentProcessing
} from '../shared/fields'
import { formatCurrency } from '../shared/formatting'
import { reviewPaymentProcessorText } from '../shared/text'
import generateQuote from '../shared/quote'
import { FormContext } from '../contexts/FormContext'
import Grid from '@material-ui/core/Grid'
import ModalWindow from '../components/ModalWindow'
import { StepContext } from '../contexts/StepContext'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'

const SectionFive = () => {
    // Context
    const [formdata, setFormdata] = useContext(FormContext)
    const [, setActivestep] = useContext(StepContext)
    // Edit Modal state
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState('')

    // Handlers
    const closeModal = () => {
        setOpen(false)
        setEdit('')
    }
    const openModal = (target) => {
        setEdit(target)
        setOpen(true)
    }
    // Recalculate quote when the payment frequency is changed.
    useEffect(() => {
        setFormdata(generateQuote(formdata))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formdata.quoteDetails.paymentFrequency])
    return (
        <Grid container justify="center" spacing={0} alignContent="center">
            <Grid container item spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Please ensure the following information is correct:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid item xs={10}>
                                <Typography variant="h6">
                                    <b>Basic Information and Contact Details</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <Typography>
                                        <b>Edit</b>
                                    </Typography>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Name of Dealership:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.dealershipName}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('DealershipName')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Primary Contact:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.contactName}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('PrimaryContact')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Primary Contact Phone:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.contactPhoneOne}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('PrimaryPhone')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Secondary Phone:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.contactPhoneTwo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('SecondaryPhone')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Primary Email:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.contactEmailOne}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('PrimaryEmail')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>Secondary Email:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.contactEmailTwo}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('SecondaryEmail')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={5}>
                                <Typography>
                                    FrazerPay, OpenEdge, or REPAY in Frazer?:
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography>
                                    {formdata.basicDetails.paymentProcessing !==
                                        undefined &&
                                        reviewPaymentProcessorText[
                                            formdata.basicDetails
                                                .paymentProcessing
                                        ]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            openModal('PaymentProcessing')
                                        }
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </center>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                <b>User Information</b>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <Typography>
                                    <b>Name</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    <b>Email</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>
                                    <b>Account Manager</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <center>
                                    <Typography>
                                        <b>Edit</b>
                                    </Typography>
                                </center>
                            </Grid>
                        </Grid>

                        {formdata.users.map((user, index) => {
                            return (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    key={`${user.first}${user.last}${index}`}
                                >
                                    <Grid item xs={4}>
                                        <Typography>
                                            {' '}
                                            {user.first} {user.last}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>{user.email}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <center>
                                            <Typography>
                                                {user.accountManager
                                                    ? 'Yes'
                                                    : ''}
                                            </Typography>
                                        </center>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <center>
                                            <IconButton
                                                size="small"
                                                onClick={() => setActivestep(2)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </center>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        {formdata.basicDetails.numberOfLocations > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        <b>Additional Locations</b>
                                    </Typography>
                                </Grid>
                                {formdata.locations.map((location, index) => {
                                    return (
                                        <React.Fragment
                                            key={`${location.locationCode}${index}`}
                                        >
                                            <Grid item xs={12}>
                                                <Typography>
                                                    <b>Location {index + 1}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Location Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {location.locationCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Dealership Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {
                                                            location.dealershipName
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Location Phone Number:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {location.phoneNumber}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Street Address:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {location.streetAddress}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        City, State ZIP:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>{`${location.city}, ${location.state} ${location.zip}`}</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        County:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {location.county}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Dealer License Number:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {
                                                            location.dealerLicenseNumber
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        Lien Holder Code:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography>
                                                        {
                                                            location.lienHolderCode
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <center>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setActivestep(3)
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </center>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })}
                            </>
                        )}
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Just in case factors changed as you
                                    completed the form (adding additional users
                                    or locations, etc), here's your estimated
                                    quote:
                                </Typography>
                                <Typography variant="body2">
                                    *Taxes may apply depending on your locale
                                    (Not calculated below).
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <b>
                                                    {formatCurrency(
                                                        formdata.quoteDetails
                                                            .priceServer
                                                    )}{' '}
                                                    {
                                                        formdata.quoteDetails
                                                            .paymentFrequency
                                                    }
                                                </b>
                                            </TableCell>
                                            <TableCell>
                                                {`${formdata.quoteDetails.serverSize} (Frazer Software included)`}
                                            </TableCell>
                                        </TableRow>
                                        {formdata.basicDetails
                                            .numberOfLocations > 0 && (
                                            <TableRow>
                                                <TableCell>
                                                    <b>
                                                        {formatCurrency(
                                                            formdata
                                                                .quoteDetails
                                                                .priceLocations
                                                        )}{' '}
                                                        Yearly
                                                    </b>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        formdata.basicDetails
                                                            .numberOfLocations
                                                    }{' '}
                                                    Location Code
                                                    {formdata.basicDetails
                                                        .numberOfLocations >
                                                        1 && 's'}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {open && (
                <ModalWindow
                    open={open}
                    title="Edit Entry"
                    actionClose={() => closeModal()}
                    maxWidth="sm"
                >
                    {edit === 'DealershipName' && (
                        <EditDealershipName
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'PrimaryContact' && (
                        <EditPrimaryContact
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'PrimaryPhone' && (
                        <EditPrimaryPhone
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'SecondaryPhone' && (
                        <EditSecondaryPhone
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'PrimaryEmail' && (
                        <EditPrimaryEmail
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'SecondaryEmail' && (
                        <EditSecondaryEmail
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                    {edit === 'PaymentProcessing' && (
                        <EditPaymentProcessing
                            formdata={formdata}
                            setFormdata={setFormdata}
                        />
                    )}
                </ModalWindow>
            )}
        </Grid>
    )
}

export default SectionFive
